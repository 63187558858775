import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function TheLab() {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState("everything");

  const projects = [
    {
      title: "Levers as a new interface paradigm",
      date: "Sept 18, 2024",
      description:
        "Musing on new paradigms for interfacing with knowledge + an initial experiment/exploration on using SAEs for a specific, grading task.",
      slug: "levers",
    },
    {
      title: "Machines can't fix bad human processes",
      date: "Sept 02, 2024",
      description: "Stop blaming the model, and start fixing the process.",
      slug: "unexplainable-systems",
    },
    {
      title: "Domain-specific sparse auto-encoders",
      date: "Jul 30, 2024",
      description:
        "Thoughts on how we could use SAEs for specific, applied purposes.",
      slug: "autograder-sae",
    },
    {
      title: "Understanding how VLMs Reason when solving visual math problems",
      date: "Mar 01, 2024",
      description:
        "Proposing a a novel image-to-text attention ratio that quantifies the extent to which a model relies on visual-only cues, as a potential indicator for effective reasoning for visual math problems.",
    },
    {
      title: "Sherpa",
      date: "Sept 15, 2023",
      description: "Scaling the oral exam!",
    },
    {
      title: "Librarian",
      date: "Jul 23, 2023",
      description:
        "Automated context-aware ads for company blogs using Twitter.",
    },
    {
      title: "Oracle",
      date: "Mar 23, 2023",
      description:
        "Transforming history curriculum into immersive, educational stories.",
    },
    {
      title: "Educational IDE",
      date: "Mar 23, 2023",
      description:
        "An online coding environment for teaching programming basics.",
    },
    {
      title: "CodeSage",
      date: "Jan 23, 2023",
      description: "Improving code quality using Github statistics.",
    },
    {
      title: "Center",
      date: "Jan 03, 2022",
      description:
        "A new tab chrome extension for staying in touch with my family & friends back home.",
    },
    {
      title: "In2Med",
      date: "Jan 02, 2022",
      description:
        "A platform to help aspiring medical students (particularly in rural and Indigenous areas) apply to medical school.",
    },
    {
      title: "WorldView",
      date: "Jan 22, 2022",
      description:
        "A chrome extension for diverse perspectives on news articles.",
    },
    {
      title: "Mehoven",
      date: "Aug 21, 2021",
      description: "Discovering personality using Spotify music taste.",
    },
    {
      title: "Lecturely",
      date: "Apr 21, 2021",
      description: "Making online lectures more collaborative and engaging.",
    },
    {
      title: "Learning Forgetting Analysis",
      date: "Jun 18, 2018",
      description: "Personalizing learning using AI and machine learning.",
    },
    {
      title: "Textify + Animation Assistant",
      date: "Mar 18, 2018",
      description: "Tools for automating tasks in Adobe After Effects.",
    },
  ].sort((a, b) => new Date(b.date) - new Date(a.date));

  const years = ["everything", 2024, 2023, 2022, 2021, 2020, 2019, 2018];

  const filteredProjects =
    selectedYear === "everything"
      ? projects
      : projects.filter(
          (project) => new Date(project.date).getFullYear() === selectedYear
        );

  return (
    <div
      className="max-w-[1200px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button
        className="text-blue-500 underline mb-4"
        onClick={() => navigate("/")}
      >
        Back
      </button>
      <div className="flex flex-row justify-between gap-4 items-center mb-16">
        <div className="text-center w-full font-times gap-2 flex flex-col">
          <h1 className="text-black-primary font-times text-4xl">the lab</h1>
        </div>
      </div>
      <div className="flex flex-col gap-4 items-start mb-8">
        <div className="flex gap-2 flex-wrap">
          {years.map((year) => (
            <button
              key={year}
              className={`px-4 py-2 border border-black ${
                selectedYear === year ? "bg-black text-white" : "text-black"
              }`}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </button>
          ))}
        </div>
        <div className="flex gap-2 flex-wrap">
          <button className="text-black px-4 py-2 border border-black">
            tinkers
          </button>
          <button className="text-black px-4 py-2 border border-black">
            projects
          </button>
          <button className="text-black px-4 py-2 border border-black">
            half-baked ideas
          </button>
          <button className="text-black px-4 py-2 border border-black">
            ml
          </button>
          <button className="text-black px-4 py-2 border border-black">
            education
          </button>
          <button className="text-black px-4 py-2 border border-black">
            climate
          </button>
          <button className="text-black px-4 py-2 border border-black">
            interpretability
          </button>
          <button className="text-black px-4 py-2 border border-black">
            interfaces
          </button>
        </div>
      </div>
      <div className="text-black mb-4">
        Found {filteredProjects.length} entries
        {selectedYear !== "everything" ? ` from ${selectedYear}` : ""}:
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProjects.map((project, index) => {
          const rotation = Math.random() * 2 - 1; // Random rotation between -1 and 1 degrees
          const hasSlug = !!project.slug;
          return (
            <div
              key={index}
              className={`bg-transparent text-black p-4 border border-black ${
                hasSlug
                  ? "hover:bg-black hover:text-white cursor-pointer"
                  : "opacity-50 cursor-not-allowed"
              }`}
              style={{ transform: `rotate(${rotation}deg)` }}
              onClick={() => hasSlug && navigate(`/the-lab/${project.slug}`)}
              title={hasSlug ? "" : "Being worked on right now!"}
            >
              <div className="font-bold text-lg">{project.title}</div>
              <div className="text-sm text-gray-400">{project.date}</div>
              <div className="text-sm">{project.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TheLab;
