import React from "react";
import { useNavigate } from "react-router-dom";

function UnexplainableSystems() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button
        className="text-blue-500 underline mb-4"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
      <div className="flex flex-col gap-4 items-start mb-16">
        <div className="text-left w-full font-times gap-2 flex flex-col">
          <h1 className="text-black-primary font-times text-4xl my-4">
            Machines can't fix bad human processes
          </h1>
          <p className="text-black opacity-50 italic mb-4">
            This is still WIP, a half-baked thought. I publish my drafts so I
            don't forget to refine them. If you have any thoughts or feedback,
            please{" "}
            <a
              href="mailto:joetey@stanford.edu"
              className="text-blue-500 hover:underline"
            >
              reach out
            </a>
            !
          </p>
          <p className="mb-4">
            We often complain about unexplainable, black-box AI systems that are
            not transparent. We don't know how a decision was made. We denigrate
            such characteristics as the reason why these systems are
            "dangerous".
          </p>

          <p className="mb-4">
            I argue, however, that this is often more of a <em>human</em>{" "}
            problem, than it is a <em>technical</em> problem.
          </p>

          <p className="mb-4">
            <strong>TLDR;</strong> an unexplainable model is often trained to
            mimic a human process that is inherently vague, ambiguous, and
            relies more on inconsistent, mental heuristics of some 'expert'.
            Yet, we, as a society, are okay with this because that person is an
            "expert" whom we trust.
          </p>

          <p className="mb-4">
            But, why should we trust this judgement? Is "vetted by an expert"
            considered "explainable"? Is it sufficient justification for a task
            that is inherently vague and abstract?
          </p>

          <p className="mb-4">
            Let's take the process of grading as an example. Some types of
            assessments, e.g. short answer assessments, <em>should</em> be very
            concrete, non-controversial processes where if two teachers had the
            same rubric, they should score both students very similarly.
          </p>

          <p className="mb-4">
            Yet, with most assessments, this is <strong>not</strong> the case.
            Teachers rely a lot on their own judgement, and are also
            subconsciously swayed by their own, unique biases when grading
            someone's answer. If you peer into these <em>rubrics</em>, they
            often contain very vague, high-level statements, even though
            logically, they can (and should be) clearly{" "}
            <strong>concretized</strong>.
          </p>

          <p className="mb-4">
            So, when you train a machine-driven auto-grader to automate this
            process, we encourage the over-fitting of the model onto the
            teacher's biases and mental heuristics. Researchers are encouraged
            to use niche, unexplainable features because most times, "experts"
            are also driven by unexplainable features. Using these features
            enables a higher agreement with the humans. Then, we blame the model
            as "unexplainable".
          </p>

          <p className="mb-4">
            By design, machines <strong>cannot</strong> model non-deterministic,
            human processes.
          </p>

          <p className="mb-4">
            Unexplainable models are not the problem, and instead, the
            non-deterministic nature of the human process is the root problem.
          </p>

          <p className="mb-4">
            You may say, "some human processes are by nature,
            non-deterministic!"
          </p>

          <p className="mb-4">
            But, ask yourself this: if the very thing being evaluated is
            compared across <strong>different human evaluators</strong>, and you
            expect a <strong>fair comparison</strong>, such as in the case of
            grading, then these human processes <strong>need</strong> to be
            deterministic. Otherwise, it wouldn't be a fair comparison.
          </p>

          <p className="mb-4">
            Sometimes, I would agree, while the process <em>should</em> be
            deterministic, it is simply not feasible for it to be. It's too
            personalized, too case-by-case, and it is, inherently, a flawed but
            "best-that-we-can-do" system.
          </p>

          <p className="mb-4">
            For these processes, don't try and model the whole process
            end-to-end, and instead, build models for deterministic
            sub-processes. The process of identifying these deterministic,
            sub-processes is, in a sense, critically evaluating a complex human
            process and rectifying it.
          </p>

          <p className="mb-4">
            Be so, so candid in what a human should do, and what a machine can
            help with. I repeat, machines cannot model non-deterministic, human
            processes, <strong>by design</strong>.
          </p>

          <p className="mb-4">
            Stop blaming the model, and start fixing the process.
          </p>

          <hr className="border-t border-black opacity-30 my-8" />

          <p className="mb-4">
            I write this because I think we will live in a world where we will
            never reach true-explainability. These models are becoming
            increasingly larger, more complex, and even with the advent of more
            advanced interpretability techniques, we can't practically rely on
            them.
          </p>

          <p className="mb-4">
            Instead, we have to give machines the <em>right</em> task to
            complete where by design, it is acceptable if they make a mistake.
            Their outputs must be understood, by us humans, and we can simply{" "}
            <em>update</em> or <em>edit</em> them in order to fix their mistake.
          </p>

          <p className="mb-4">
            In fact, if we give machines the <em>right</em> task, they will be
            deeply accurate and perform comparably (if not, better) with humans.
            This task needs to be small enough, non-controversial, and
            deterministic.
          </p>

          <p className="mb-4">
            In human systems, however, giving machines the <em>right</em> task
            is the hard part. Often, our human processes are so complex, muddled
            up, reliant on a vague definition of "expert trust", that it is
            non-trivial to give machines the <em>right</em> task.
          </p>

          <p className="mb-4">
            I hope that the rise of these systems will prompt greater, critical
            thinking into our complex, imperfect human systems.
          </p>
        </div>
      </div>
    </div>
  );
}

export default UnexplainableSystems;
