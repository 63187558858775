import { Tooltip } from "@mui/material";
import fineTunedFeatures from "../features/fine_tuned_features.json";
import pretrainedFeatures from "../features/pre_trained_features.json";

export default function FeatureDetailsSidebar({ selectedFeature, indexKey = "featureIndex", modelType = "fine-tuned", fixedHeight=true }) {
  if (!selectedFeature) {
    return <p>Hover over or select a feature to see details</p>;
  }
  
  const features = modelType === "fine-tuned" ? fineTunedFeatures : pretrainedFeatures;
  const featureData = features.find(feature => feature.index === selectedFeature[indexKey]);
  const samples = featureData.high_act_samples;
  const totalSamples = samples.length;
  const scoreCounts = [0, 0, 0];
  samples.forEach(sample => {
    if (sample.score >= 0 && sample.score <= 2) {
      scoreCounts[sample.score]++;
    }
  });
  const scorePercentages = scoreCounts.map(count => (count / totalSamples) * 100);

  return (
    <>
      <h2 className="font-bold mb-2">{featureData.label} ({selectedFeature[indexKey]})</h2>
      <div className="flex items-center mb-2">
        <p className="text-sm text-gray-600 mr-2 w-1/4">
          Confidence: {featureData.confidence.toFixed(0)}%
        </p>
        <div className="w-3/4 h-2 bg-gray-200 rounded-full">
          <div 
            className="h-full bg-orange-500 rounded-full"
            style={{width: `${featureData.confidence}%`}}
          ></div>
        </div>
      </div>
      <div className="flex items-center mb-2">
        <div className="w-full text-gray-400">
          {scorePercentages.map((percentage, index) => {
            const isHighest = percentage === Math.max(...scorePercentages);
            return (
              <p key={index} className={`text-sm ${isHighest ? 'font-bold text-gray-800' : ''}`}>
                {percentage.toFixed(0)}% of highly-activating samples have a score of {index}
              </p>
            );
          })}
        </div>
      </div>
      <p className="mb-2">{featureData.attributes}</p>
      
      <div className={`${fixedHeight ? 'h-[300px]' : 'h-screen'} overflow-y-auto`}>
        {samples
          .sort((a, b) => b.act - a.act)
          .map((sample, index) => (
            <div key={index} className="mb-2">
              <div 
                className="relative p-2"
                style={{
                  backgroundColor: 'rgba(255, 165, 0, 0.1)',
                  borderRadius: '4px'
                }}
              >
                <div 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: `${sample.act * 100}%`,
                    height: '100%',
                    backgroundColor: sample.score === 2 ? 'rgba(0, 255, 0, 0.5)' : 
                                     sample.score === 1 ? 'rgba(255, 165, 0, 0.5)' : 
                                     'rgba(255, 0, 0, 0.5)',
                    borderRadius: '4px'
                  }}
                />
                <Tooltip title={sample.text} arrow>
                  <p className="relative z-10">
                    {sample.text.length > 400 ? `${sample.text.substring(0, 400)}...` : sample.text}
                  </p>
                </Tooltip>
                <p className="relative z-10 text-sm text-gray-400 text-right">
                  From a response that was scored by a teacher a {sample.score}/2
                </p>
                <p className="relative z-10 text-sm text-gray-400 text-right">
                  Activation score: {sample.act.toFixed(2)}
                </p>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
}