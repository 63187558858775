import React from "react";
import { useNavigate } from "react-router-dom";

function AutograderSAE() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button
        className="text-blue-500 underline mb-4"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
      <div className="flex flex-col gap-4 items-start mb-16">
        <div className="text-left w-full font-times gap-2 flex flex-col">
          <h1 className="text-black-primary font-times text-4xl my-4">
            Extracting Domain-Specific Features using Sparse Auto-Encoders
          </h1>
          <p className="mb-4">
            As of late, I've been very curious about some of Linus' work on
            applied interpretability. It builds upon Anthropic's work on
            extracting human interpretable features from large language models,
            specifically using sparse auto-encoders.
          </p>

          <p className="mb-4">
            These features are deeply diverse, ranging from highly abstract to
            very granular/specific, as well as covering a limitless number of
            topics. By knowing what these features mean, as well as the specific
            neurons that correlates to these features, we are able to{" "}
            <em>clamp</em> these specific features to artificially high or low
            values in order to induce a particular behavior. This is powerful,
            and allows for a new mechanism to steer and control these very
            powerful models.
          </p>

          <p className="mb-4">
            Such granular understanding of these models also enables a new set
            of interfaces that were previously not possible. I'm particularly
            excited about creating more <em>explainable</em> interfaces that
            give us, the end-user, a more deterministic, confident explanation
            for a certain decision. As an education-nerd myself, I think this is
            one of the biggest limitations of using LLMs reliably in our current
            schooling system. Right now, it's hard for us to confidently trust
            these tools.
          </p>

          <p className="mb-4">
            While the quantity and diversity of these identified features are
            important for understanding these models, from an applied point of
            view, most of these features are not very useful. It also takes a
            decent amount of compute + cost to train an SAE and label these
            identified features, which for very particular use cases, feels a
            little wasteful and inefficient.
          </p>

          <p className="mb-4">
            I'm really curious about the ability of SAEs to extract{" "}
            <em>domain-specific</em> features from large language models, and
            exploring the extent to which we're able to control what features to
            extract.
          </p>

          <p className="mb-4">
            For example, let's take the example case of auto-grading: trying to
            figure out what <strong>specific</strong> features an LLM may look
            at in order to <strong>grade</strong> a particular student response.
            Is it the presence of a specific keyword? Is it your spelling? How
            specific/granular are these features? Can we <em>tune</em> and
            calibrate these features for a specific data-set? Could we perhaps
            artificially <em>induce</em> these features, literally influencing
            what a model cares about in such decision-making? I think this has
            many implications beyond just education, but in a variety of more
            specialized domains.
          </p>

          <p className="mb-4">
            I'm also curious about ways to do this with smaller models.
          </p>

          <p className="mb-4">
            My room-mate Nick, and I are exploring this particular question, in
            the realm of education. We seek to answer two main questions:
          </p>

          <ol className="list-decimal list-inside mb-4">
            <li className="mb-2">
              Is it possible to extract domain-specific features using SAEs
              using smaller models? How can we do this most effectively?
            </li>
            <p>
              There are various ways to do this, and we'll try to explore a
              variety. Some include fine-tuning smaller encoder models and
              training SAEs on that. Others involve decoder models, and using
              prompting as a way to infer meaning.
            </p>
            <br />
            <li>
              If 1) is possible, can we build a novel interface for students and
              teachers to <em>dissect</em> a response/essay in a way that is
              more <em>explainable</em>?
            </li>
            <p className="mt-2">
              Imagine an interface that displays some key, general features like
              grammar, cohesion, fluency, or even more specific topics like the
              presence of certain ideas. Clicking on these features highlights{" "}
              <strong>specific</strong> parts of a student's response that
              correlates to these features.
            </p>
          </ol>
          <p className="mb-4">
            Stay tuned for more updates on some initial experiments...
          </p>
        </div>
      </div>
    </div>
  );
}

export default AutograderSAE;
