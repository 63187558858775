import React, { useState, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import fineTunedFeatures from "./features/fine_tuned_features.json";
import FeatureDetailsSidebar from "./components/FeatureDetailsSidebar";

function AutoEdCoderViewer() {
  const [inputText, setInputText] = useState("");
  const [heatmapData, setHeatmapData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const sampleTexts = [
    "Pandas in China and koalas in Australia are similar because they are both specialists. They both usually only eat one kind of food: bamboo for pandas and eucalyptus leaves for koalas. If these animals were taken from their food source, chances are they would die. This is not like pythons, however. Pythons, when given a mild to warm climate to live in, will adapt to the surroundings and food would not be a factor. As long as there are animals in their environment, pythons will have food. However, pythons, for the moment, are not suitable for colder climates.",
    "Pandas in China are similar to koalas in Australia because they are both specialists, meaning they can only survive in a certain area. This is because the panda eats almost nothing but bamboo, and the koala eats almost only eucalyptus leaves. The python, however, is different because it is a generalist. They can handle change and adapt to it, making it easy for them to find food almost anywhere.",
    "Different between China's panda and Australia's koalas is that China's panda eats almost nothing but bamboo and Australia's koalas eat eucalyptus leaves almost exclusively."
  ];

  const fetchHeatmapData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://josephtey--heatmap-generator.modal.run', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: inputText,
          model_type: "fine-tuned",
          first_n_features: 500
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setHeatmapData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const featureActivations = useMemo(() => {
    if (!heatmapData) return {};
    const activations = {};
    heatmapData.forEach((item) => {
      item.feature_activations.forEach((activation, index) => {
        if (activation > 0) {
          if (!activations[index]) {
            activations[index] = [];
          }
          activations[index].push({
            activation: activation,
            sentence: item.sentence
          });
        }
      });
    });
    return activations;
  }, [heatmapData]);

  const topActivatedFeatures = useMemo(() => {
    const features = Object.entries(featureActivations)
      .map(([featureIndex, activations]) => ({
        featureIndex: parseInt(featureIndex),
        averageActivation: activations.reduce((sum, item) => sum + item.activation, 0) / activations.length,
        numberOfSentences: activations.length,
        confidence: fineTunedFeatures[parseInt(featureIndex)].confidence,
        numHighActSamples: fineTunedFeatures[parseInt(featureIndex)].high_act_samples.length
      }))
      .filter(feature => feature.numHighActSamples >= 10);

    // Calculate the maximum average activation
    const maxAverageActivation = Math.max(...features.map(f => f.averageActivation));

    // Normalize the average activations
    const normalizedFeatures = features.map(feature => ({
      ...feature,
      normalizedActivation: feature.averageActivation / maxAverageActivation
    }));

    return normalizedFeatures.sort((a, b) => {
      const scoreA = a.normalizedActivation * 0.5 + a.confidence * 0.5;
      const scoreB = b.normalizedActivation * 0.5 + b.confidence * 0.5;
      return scoreB - scoreA;
    });
  }, [featureActivations, fineTunedFeatures]);

  const getHighlightStyle = (sentence) => {
    if (hoveredFeature || selectedFeature) {
      const feature = hoveredFeature || selectedFeature;
      const activations = featureActivations[feature.featureIndex];
      const matchingActivation = activations.find(item => item.sentence === sentence);
      if (matchingActivation) {
        const opacity = Math.min(matchingActivation.activation * 2, 1);
        return {
          backgroundColor: `rgba(255, 165, 0, ${opacity})`,
          transition: 'background-color 0.3s'
        };
      }
    }
    return {};
  };

  return (
    <div
      className="max-w-[1500px] px-12 mx-auto my-0 my-12"
      id="container"
    >
      <div className="flex flex-col justify-center items-center my-16">
        <h1 className="text-black-primary font-times text-4xl text-center">
          What does an LLM auto-grader <i>see</i> in a student response?
        </h1>
      </div>
      {!isLoading && !heatmapData && (
        <div className="flex flex-col gap-4 mb-8">
          <div className="flex justify-start gap-2">
            {sampleTexts.map((text, index) => (
              <a
                key={index}
                className="text-gray-500 underline cursor-pointer hover:text-gray-700 transition-colors duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  setInputText(text);
                }}
                href="#"
              >
                sample #{index + 1}
              </a>
            ))}
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm overflow-auto h-[300px]">
            <textarea
              className="w-full h-full p-2 border-none resize-none focus:outline-none text-xl leading-relaxed"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Enter your text here..."
            />
          </div>
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded-lg opacity-60 hover:opacity-100 transition-opacity duration-300 flex items-center self-end"
            onClick={fetchHeatmapData}
          >
            Analyze Text
            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </button>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      )}
      {isLoading && (
        <div className="flex justify-center items-center h-64">
          <CircularProgress style={{ color: 'orange' }} />
        </div>
      )}
      {heatmapData && (
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-4 w-2/3">
            <div className="bg-white rounded-lg p-4 shadow-sm overflow-auto h-[500px]">
              <p className="text-xl leading-relaxed">
                {heatmapData.map((item, index) => (
                  <span key={index} style={getHighlightStyle(item.sentence)}>{item.sentence} </span>
                ))}
              </p>
            </div>
            <div className="flex flex-wrap justify-start gap-2 mb-8">
              {topActivatedFeatures.slice(0, 10).map((feature, index) => {
                return (
                  <div 
                    key={index} 
                    className={`relative rounded px-3 py-1 text-s text-white cursor-pointer transition-colors duration-300 overflow-hidden ${
                      selectedFeature === feature ? 'bg-orange-200 opacity-60 text-black' : 
                      hoveredFeature === feature ? 'bg-orange-300 opacity-60 text-black' : 'bg-black opacity-60'
                    }`}
                    onMouseEnter={() => setHoveredFeature(feature)}
                    onMouseLeave={() => setHoveredFeature(null)}
                    onClick={() => setSelectedFeature(feature)}
                  >
                    <div 
                      className="absolute top-0 left-0 h-full bg-orange-500 opacity-60"
                      style={{width: `${feature.normalizedActivation * 100}%`}}
                    ></div>
                    <span className="relative z-10">{fineTunedFeatures[feature.featureIndex].label}</span>
                  </div>
                )
              })}
              <span 
                className="px-3 py-1 text-s text-black opacity-50 cursor-pointer"
                onClick={() => setShowAllFeatures(true)}
              >
                + many more
              </span>
            </div>
            {showAllFeatures && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-4 w-2/3 h-2/3 overflow-auto">
                  <h2 className="text-xl font-bold mb-4">All Features</h2>
                  <div className="flex flex-wrap gap-2">
                    {topActivatedFeatures.slice(10).map((feature, index) => (
                      <div 
                        key={index}
                        className={`relative rounded px-3 py-1 text-s cursor-pointer transition-colors duration-300 overflow-hidden ${
                          selectedFeature === feature ? 'bg-orange-200 text-black' : 
                          'bg-black text-white opacity-60 hover:bg-orange-300 hover:text-black'
                        }`}
                        onClick={() => {
                          setSelectedFeature(feature);
                          setShowAllFeatures(false);
                        }}
                      >
                        <div 
                          className="absolute top-0 left-0 h-full bg-orange-500 opacity-60"
                          style={{width: `${feature.normalizedActivation * 100}%`}}
                        ></div>
                        <span className="relative z-10">{fineTunedFeatures[feature.featureIndex].label}</span>
                      </div>
                    ))}
                  </div>
                  <button 
                    className="mt-4 px-4 py-2 bg-gray-200 rounded"
                    onClick={() => setShowAllFeatures(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="w-1/3 flex flex-col gap-4">
            <div className="bg-white rounded-lg p-4 shadow-sm overflow-auto">
              <FeatureDetailsSidebar selectedFeature={hoveredFeature || selectedFeature} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AutoEdCoderViewer;
