import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import TheLab from "./components/TheLab";
import MyThoughts from "./components/MyThoughts";
import WhatIBelieveIn from "./components/WhatIBelieveIn";
import MyPartOfTheInternet from "./components/MyPartOfTheInternet";
import BooksImReading from "./components/BooksImReading";
import EverythingElse from "./components/EverythingElse";
import AutoEdCoder from "./projects/auto-ed-coder/viewer";
import reportWebVitals from "./reportWebVitals";
import AutograderSAE from "./lab/AutograderSAE";
import UnexplainableSystems from "./lab/UnexplainableSystems";
import AutoEdCoderScoredFeatures from "./projects/auto-ed-coder/features";
import Levers from "./lab/Levers";
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/the-lab" element={<TheLab />} />
        <Route path="/my-thoughts" element={<MyThoughts />} />
        <Route path="/what-i-believe-in" element={<WhatIBelieveIn />} />
        <Route
          path="/my-part-of-the-internet"
          element={<MyPartOfTheInternet />}
        />
        <Route path="/books-im-reading" element={<BooksImReading />} />
        <Route path="/everything-else" element={<EverythingElse />} />

        {/* the lab */}
        <Route path="/the-lab/autograder-sae" element={<AutograderSAE />} />
        <Route path="/the-lab/levers" element={<Levers />} />
        <Route path="/auto-ed-coder" element={<AutoEdCoder />} />
        <Route
          path="/auto-ed-coder/features"
          element={<AutoEdCoderScoredFeatures />}
        />

        <Route
          path="/the-lab/unexplainable-systems"
          element={<UnexplainableSystems />}
        />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
