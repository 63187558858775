import React from "react";
import { useNavigate } from "react-router-dom";

function WhatIBelieveIn() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button
        className="text-blue-500 underline mb-4"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
      <div className="flex flex-col gap-4 items-start mb-16">
        <div className="text-left w-full font-times gap-2 flex flex-col">
          <h1 className="text-black-primary font-times text-4xl">
            what i believe in
          </h1>
          <ul className="list-disc pl-5">
            <li>sequentially failing</li>
            <li>deduction, care and passion is in short order</li>
            <li>asking the right question is the most important thing.</li>
            <li>Durable Learning</li>
            <li>people should be more forgiving</li>
            <li>
              You cannot force yourself to be focused; it is earned, not found
            </li>
            <li>don't chase; just be.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default WhatIBelieveIn;
