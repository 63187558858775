import React from "react";
import { useNavigate } from "react-router-dom";

function EverythingElse() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button
        className="text-blue-500 underline mb-4"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
      <div className="flex flex-row justify-between gap-4 items-center mb-16">
        <div className="text-center w-full font-times gap-2 flex flex-col">
          <h1 className="text-black-primary font-times text-4xl">
            everything else
          </h1>
        </div>
      </div>
    </div>
  );
}

export default EverythingElse;
